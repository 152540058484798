import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/Text";
import { TextField, CircularProgress } from "@mui/material";
import { registerUserEmail } from "../../../services/redux/modules/user/thunk";
import { useAppDispatch } from "../../../services/redux/tools";
import { selectUser } from "../../../services/redux/modules/user/selector";
import { getApiEndpoint } from "../../../services/tools";
import React from "react";
import s from "../index.module.css";
import FullscreenCentered from "../../../components/FullscreenCentered";

export default function Register() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  const submit = useCallback(
    async (ev: React.SyntheticEvent) => {
      if(loading) {
        return;
      }
      
      ev.preventDefault();
      setLoading(true);
      const resultAction = await dispatch(registerUserEmail(email));
      setLoading(false);
      if (registerUserEmail.fulfilled.match(resultAction) && resultAction.payload) {
        setTimeout(() => {
          window.location.href = getApiEndpoint().toString() + '/oauth/spotify';
        }, 4000);
      }
    },
    [email, dispatch, navigate],
  );

  if (loading) {
    return (
      <FullscreenCentered>
        <CircularProgress />
        <div>
          <Text element="h3">Registering your email address...</Text>
        </div>
      </FullscreenCentered>
    );
  }

  return (
    <div className={s.root}>
      <Text element="h1" className={s.title}>
        Register
      </Text>
      <Text className={s.welcome}>
        Please register the email address associated with your Spotify account to access this app.
      </Text>
      <div>
        <form onSubmit={submit}>
          <TextField
            hiddenLabel
            variant="outlined"
            size="small"
            placeholder="Your email address..."
            fullWidth
            value={email}
            className={s.input}
            onChange={ev => setEmail(ev.target.value)}
          />
          <a
            href="#"
            className={`${s.link} ${s.submit}`}
            onClick={submit}
          >
            Register
          </a>
        </form>
      </div>
    </div>
  );
}